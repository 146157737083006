import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

export default function SetDateModal({showModal, setShowModal, setDate}){

    const handleClose = () => {
        setShowModal(false);
    }

    const getTwoDigits = (number) => {
        return ("0" + number).slice(-2);
    }

    let today = new Date();
    const [controlDate, setControlDate] = useState(today.getFullYear() + "-" + getTwoDigits(today.getMonth()+1) + "-" + getTwoDigits(today.getDate()));

    const handleSetDate = () => {        
        let lines = controlDate.split("-");        
        setDate(new Date(lines[0], lines[1]-1, lines[2]));
        setShowModal(false);
    }

    const handleControlDate = (e) => {
        setControlDate(e.target.value);
    }   

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Seleccionar fecha</Modal.Title>
            </Modal.Header>

            <Modal.Body>                
                <input type="date" value={controlDate} onChange={handleControlDate} />                
            </Modal.Body>

            <Modal.Footer>
            <Button variant="primary" onClick={handleSetDate}>Ir</Button>
                <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
            </Modal.Footer>
        </Modal>
    );

}