import { Button, Modal } from "react-bootstrap";
import { TASK_DOWNLOAD_URL } from "../../utils/config";
import { formatDate } from "../../utils/formats";

export default function DownloadModal({showModal, setShowModal, date}){
    
    const formattedDate = formatDate(date);

    const handleClose = () => {
        setShowModal(false);
    }

    const handleDownloadWeek = () =>{
        downloadFile("week");
    }

    const handleDownloadMonth = () =>{
        downloadFile("month");
    }

    const handleDownloadYear = () =>{
        downloadFile("year");
    }

    const downloadFile = async (filter) =>{
        
        const url = `${TASK_DOWNLOAD_URL}?type=pdf&filter=${filter}&date=${formattedDate}`;
        console.log(url);
        
        const result = await fetch(url,{
            method: "GET",
            headers: {
                //'Authorization': localStorage.getItem("authorization")
            }
        });

        if(result.status==200){
            const blob = await result.blob();
            const href = window.URL.createObjectURL(blob);
    
            const a = document.createElement('a');
            a.target = "_blank";
            //a.setAttribute("download", "file.pdf");
            a.href = href;
            a.click();            
        }
        
        handleClose();
    }

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Descargar actividades</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                Elige si quieres descargar por 
                <Button onClick={handleDownloadWeek} variant="link">semana</Button>,
                <Button onClick={handleDownloadMonth} variant="link">mes</Button> o
                <Button onClick={handleDownloadYear} variant="link">año</Button>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancelar</Button>                
            </Modal.Footer>
        </Modal>
    );
}