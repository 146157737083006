import { useState } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { getDayName, getNextDay, getPreviousDay, isToday } from "../../utils/dateHelper";
import { formatDate } from "../../utils/formats";
import DownloadModal from "./DownloadModal";
import SetDateModal from "./SetDateModal";
import { BsCalendar2Date } from "react-icons/bs";

export default function DayHeader({date, setDate}){

    const today = isToday(date);
    const formattedDate = formatDate(date);
    const dateString = getDayName(date) + " " + formattedDate;
    
    const increaseDate = () => {
        setDate(getNextDay(date));
    }
    
    const decreaseDate = () => {
        setDate(getPreviousDay(date));
    }

    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const handleShowDownloadModal = () => setShowDownloadModal(true);

    const handleDownload = () => {
        handleShowDownloadModal();
    }

    const [showSetDateModal, setShowSetDateModal] = useState(false);
    const handleShowSetDateModal = () => setShowSetDateModal(true);
    const handleSetDate = () => {
        handleShowSetDateModal();
    }

    return (
        <div className="DayHeader data-section">    
            <DownloadModal showModal={showDownloadModal} setShowModal={setShowDownloadModal} date={date} />     
            <SetDateModal showModal={showSetDateModal} setShowModal={setShowSetDateModal} setDate={setDate} />
            <div>
                {today && "Hoy "}
                {dateString}                 
                <div className="d-inline" >                        
                    <div className="d-inline" style={{padding: 10}}>
                        <BsCalendar2Date onClick={handleSetDate} />
                    </div>
                    <button onClick={handleDownload} className="btn btn-primary btn-sm">
                        Descargar
                    </button>
                </div>
            </div> 
            <div>
                <button onClick={decreaseDate} className="btn icon-btn"><BsChevronLeft /></button>
                <button onClick={increaseDate} className="btn icon-btn"><BsChevronRight /></button>
            </div>
        </div>
    );
}